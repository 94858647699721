import { Animator, Dots, FrameSVGCorners, FrameSVGLines, GridLines, MovingLines, Text } from "@arwes/react";
import React from "react";
import { BsFiletypeDoc, BsGithub, BsTelegram, BsTwitter, BsFillRocketTakeoffFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";


class _IndexPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            overIndex: -1,
            messages: [
                'Welcome to WRB',
                'Still building...',
            ],
            showDialog: false,
            links: [
                // {
                //     title: '[ Fair Launch V1 ]',
                //     onClick: () => {
                //         // window.open('https://github.com/WhiteRiverBay/evm-fair-launch', '_blank')
                //         window.location.href = 'https://github.com/WhiteRiverBay/evm-fair-launch'
                //     }
                // },
                {
                    title: '[ epump.fun ]',
                    onClick: () => {
                        // window.open('https://github.com/rocketprotocollab/rocket-contracts', '_blank')
                        window.location.href = 'https://epump.fun'
                    }
                },

                {
                    title: '[ rocket.meme ]',
                    onClick: () => {
                        // window.open('https://github.com/rocketprotocollab/rocket-contracts', '_blank')
                        window.location.href = 'https://rocket.meme'
                    }
                },

                {
                    title: '[ Rocket Protocol ]',
                    onClick: () => {
                        // window.open('https://github.com/rocketprotocollab/rocket-contracts', '_blank')
                        window.location.href = 'https://github.com/rocketprotocollab/rocket-contracts'
                    }
                },
                {
                    title: '[ Uni-Locker ]',
                    onClick: () => {
                        // window.open('https://github.com/rocketprotocollab/uni-locker', '_blank')
                        window.location.href = 'https://github.com/rocketprotocollab/uni-locker'
                    }
                },
                {
                    title: '[ Contracts ]',
                    onClick: () => {
                        this.setState({
                            showDialog: true,
                            messages: [
                                'Deployed Contract on Base Chain',
                                'Rocket Protocol Factory: 0x3d904547C301e6a89f1c63f3Ba35dCbFd28222f7',
                                'Uni Locker: 0x99090d2d220901De904c6E3d003D7ceD4B6eC2A4',
                            ]
                        })
                    }
                }

            ]
        }
    }

    componentDidMount() {

    }

    play = (file) => {
        try {
            this.audio = new Audio();
            this.audio.src = file;
            this.audio.loop = false;
            this.audio.play();

        } catch (e) {
            console.log(e);
        }
    }

    render() {

        const props = this.props;
        const state = this.state;

        return (
            <div>
                <Animator duration={{ interval: 10 }}>
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        // backgroundColor: '#000906',
                        backgroundColor: '#000916',
                        backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)'
                        // backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(195, 100%, 25%, 0.25) 0%, hsla(195, 100%, 25%, 0.12) 50%, hsla(195, 100%, 25%, 0) 100%)'
                    }}>
                        <GridLines
                            lineColor='hsla(180, 100%, 75%, 0.05)'
                            distance={30}
                        />
                        <Dots
                            color='hsla(180, 100%, 75%, 0.08)'
                            distance={30}
                        />
                        <MovingLines
                            // lineColor='hsla(180, 100%, 75%, 0.07)'
                            lineColor='hsla(210, 100%, 75%, 0.2)'
                            distance={30}
                            sets={20}
                        />
                        {/* absolute center*/}
                        <div style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            opacity: 0.2,
                            height: '98%',
                        }}>
                            <img src="/assets/images/aa2.png" style={{
                                maxHeight: '100%',
                            }} alt="" />
                        </div>
                        {/* Absolute Center  */}
                        <div style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '3rem',
                            minWidth: '260px',
                            textAlign: 'center',
                            zIndex: 50,
                        }}>
                            <Animator active={true} duration={{ enter: 1, exit: 1 }}>
                                <Text
                                    style={{ color: '#ddd', fontFamily: 'gothic,monospace', textShadow: '0 0 5px #27e1fa', }}
                                    manager='decipher'
                                    easing='outSine'
                                    fixed
                                >
                                    WHITE RIVER BAY
                                </Text>
                                <Text
                                    style={{
                                        color: '#ddd',
                                        fontSize: '1.5rem',
                                        // fontFamily: 'Titillium Web,"Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif', textShadow: '0 0 5px #27e1fa',
                                        textShadow: '0 0 5px #27e1fa',
                                        opacity: 0.8,
                                        fontFamily: 'gothic,monospace'
                                    }}

                                    manager='decipher'
                                    opacity={0.5}
                                    easing='outSine'
                                    fixed
                                >
                                    &gt;&gt;&gt;&nbsp;Technical supporter of the crypto&nbsp;&lt;&lt;&lt;
                                </Text>
                            </Animator>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                fontSize: '1.5rem',
                                color: '#ddd',
                                // 发光
                                textShadow: '0 0 5px #27e1fa',
                                opacity: 0.8,
                                fontFamily: 'gothic,monospace'
                            }}>
                                {/* <Animator active={true} duration={{ enter: 1, exit: 1 }}>
                                    
                                </Animator> */}
                                <Animator active={true} duration={{ enter: 1, exit: 1 }}>

                                    {state.links.map((item, index) => {
                                        return (
                                            <div style={{
                                                marginRight: index == state.links.length - 1 ? 0 : '1.5rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Text
                                                    key={index}
                                                    style={{
                                                        color: index == state.overIndex ? 'hsl(120, 75%, 50%)' : '#ddd',
                                                        fontSize: index == state.overIndex ? '1.2rem' : '1rem',
                                                        fontFamily: 'gothic,Titillium Web,"Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif', textShadow: '0 0 5px #27e1fa',
                                                        cursor: 'pointer'
                                                    }}
                                                    manager='decipher'
                                                    opacity={
                                                        index == state.overIndex ? 1 : 0.5
                                                    }
                                                    easing='outSine'
                                                    onMouseOver={(e) => {
                                                        this.setState({
                                                            overIndex: index
                                                        })
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        this.setState({
                                                            overIndex: -1
                                                        })
                                                    }}
                                                    onClick={() => {
                                                        this.play('/assets/sounds/click.webm');
                                                        item.onClick();
                                                    }}
                                                    fixed
                                                >
                                                    {item.title}
                                                </Text>
                                            </div>
                                        )
                                    })}
                                </Animator>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: '3.2rem',
                                fontSize: '1.5rem',
                                color: '#ddd',
                                // 发光
                                textShadow: '0 0 5px #27e1fa',
                                opacity: 0.5
                            }}>
                                {/* <BsGithub
                                    onMouseEnter={(e) => {
                                        // this.play('/assets/sounds/click.mp3');
                                    }}
                                    onClick={() => {
                                        this.play('/assets/sounds/click.webm');
                                        window.location.href = 'https://github.com/WhiteRiverBay'
                                    }} /> */}
                                {/* <BsFillRocketTakeoffFill onClick={() => {
                                    this.play('/assets/sounds/click.webm');
                                    window.location.href = 'https://epump.fun'
                                }} /> */}

                                <BsTelegram onClick={() => {
                                    // alert building
                                    this.play('/assets/sounds/click.webm');
                                    // alert('building')
                                    // window.open('https://t.me/ethan_ca', '_blank')
                                    window.location.href = 'https://t.me/ethan_ca'
                                }} />
                                {/* <BsTwitterX /> */}
                                <BsTwitter onClick={() => {
                                    this.play('/assets/sounds/click.webm');
                                    window.location.href = 'https://twitter.com/WhiteRiverBay'
                                    // window.open('https://twitter.com/WhiteRiverBay', '_blank')
                                }} />
                                {/* 
                            <BsFiletypeDoc onClick={() => {
                                this.play('/assets/sounds/click.webm');
                                alert('building')
                            }} /> */}

                                <HiOutlineMail onClick={() => {
                                    this.play('/assets/sounds/click.webm');
                                    const link = 'mailto:ethan@wrb.ltd'
                                    const element = document.createElement('a');
                                    element.setAttribute('href', link);
                                    element.setAttribute('target', '_blank');
                                    element.style.display = 'none';
                                    document.body.appendChild(element);
                                    element.click();
                                }} />
                            </div>
                        </div>
                    </div>
                    {/* copy right */}
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        textAlign: 'center',
                        color: '#ccc',
                        fontSize: '14px',
                        padding: '1rem',
                        display: 'inline-block',
                        // fontFamily: 'monospace',
                    }}>
                        <Animator active={true} duration={{ enter: 1.5, exit: 1.5 }}>
                            <Text
                                style={{ color: '#ccc', fontFamily: 'gothic,monospace', textShadow: '0 0 5px #27e1fa', transition: 'opacity 250ms ease-out,color 250ms ease-out', opacity: 0.5 }}
                                manager='decipher'
                                easing='outSine'
                                fixed
                            >
                                WHITE RIVER BAY &copy; 2024
                            </Text>
                        </Animator>
                    </div>
                </Animator>

                {/* mask  */}
                <div style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    // backgroundColor: '#000906',
                    backgroundColor: '#000916',
                    backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)',
                    // backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(195, 100%, 25%, 0.25) 0%, hsla(195, 100%, 25%, 0.12) 50%, hsla(195, 100%, 25%, 0) 100%)'
                    opacity: 0.5,
                    // show
                    display: state.showDialog ? 'block' : 'none',
                }} onClick={() => {
                    this.play('/assets/sounds/click.webm');
                    this.setState({
                        showDialog: false
                    })
                }}>
                </div>
                {/* center block */}
                <div style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '3rem',
                    minWidth: '260px',
                    height: 500,
                    width: '60%',
                    textAlign: 'center',
                    backgroundColor: '#000916',
                    opacity: 1,
                    zIndex: 100,
                    // show
                    display: state.showDialog ? 'block' : 'none',
                }}>
                    <div style={{
                        position: 'relative',
                        backgroundColor: 'hsl(180, 75%, 10%)',
                        borderTop: '1px solid hsl(180, 75%, 50%)',
                        borderBottom: '1px solid hsl(180, 75%, 50%)',
                        fontSize: '1.2rem',
                        height: '100%',
                        color: '#ddd',
                        // fontFamily: 'monospace'
                        textAlign: 'left',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        fontFamily: 'Titillium Web,"Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif', textShadow: '0 0 5px #27e1fa'
                    }}>
                        <Animator combine manager='sequence'>
                            {state.messages.map((item, index) => {
                                return (
                                    <Animator key={index}>
                                        <Text>{item}</Text>
                                    </Animator>
                                )
                            })}
                        </Animator>
                    </div>
                    {/* close button */}
                    <div style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        fontSize: '1.2rem',
                        color: '#ddd',
                        // fontFamily: 'monospace'
                        textAlign: 'right',
                        paddingRight: '1rem',
                        paddingTop: '1rem',
                        cursor: 'pointer',
                        // 发光
                        textShadow: '0 0 5px #27e1fa',
                        opacity: 0.5
                    }}
                        onClick={() => {
                            this.play('/assets/sounds/click.webm');
                            this.setState({
                                showDialog: false
                            })
                        }}
                    >
                        CLOSE

                    </div>

                </div>

            </div>
        );
    }
}

function IndexPage(props) {
    return <_IndexPage />;
}

export default IndexPage;