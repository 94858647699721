import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import IndexPage from './pages/home';
import reportWebVitals from './reportWebVitals';
// import { createAppTheme,  createAppStylesBaseline } from '@arwes/react';

const root = ReactDOM.createRoot(document.getElementById('root'));


// const theme = createAppTheme();
// const stylesBaseline = createAppStylesBaseline(theme);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexPage  />} />
      {/* <Route path="/" element={<IndexPage styles={stylesBaseline} />} /> */}
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
